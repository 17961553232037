import { Button, Box, Grid } from "@mui/material";

export default function Page(props) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(props.totalPosts / props.postPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} textAlign="left" sx={{ color: "gray" }}>
          page {props.currentPage}
        </Grid>
        {pageNumbers.map((number) => (
          <Grid item key={number}>
            <button
              style={{
                margin: "0.3rem 0.3rem 0 0",
                color: "black",
                backgroundColor: "orange",
                border: "none",
                padding: "1rem",
                outline: "none",
                borderRadius: "1rem",
              }}
              onClick={() => props.paginate(number)}
            >
              {number}
            </button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
