import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { data } from "./data.js";
import { supabase } from "./features/client";

function Track() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    datum();
  }, []);
  async function datum() {
    let { data: term, error } = await supabase.from("orders").select("*");
    setData(term);
  }
  return (
    <div
      style={{
        paddingTop: "7rem",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Container>
        <h3 style={{ color: "orange" }}>Track Order</h3>
        <Form>
          <InputGroup className="my-3">
            {/* onChange for search */}
            <Form.Control
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Bar"
              style={{
                width: "16rem",
                borderRadius: "0.5rem",
                border: "1px solid gray",
                height: "2rem",
              }}
            />
          </InputGroup>
        </Form>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Transaction No.</th>

              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter((item) => {
                return search.toLowerCase() === ""
                  ? item
                  : item.track.toLowerCase().includes(search);
              })
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.track}</td>

                  <td>{item.status}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default Track;
