import contact from "./features/images/contact.jpg";
import bg from "./features/images/bg.jpg";
import Textfield from "./features/textfield";
import Inputfield from "./inputfield";
import "./contact.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Contact() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  return (
    <div className="contact-container" style={{ paddingTop: "7rem" }}>
      <div>
        <img src={contact} alt=" " className="contact-img" />
      </div>
      <div>
        <h3 style={{ textAlign: "center", color: "orange" }}>
          Have Some Questions?
        </h3>{" "}
        <a href="http://wa.me/2347015776954">
          <div
            className="img-botton"
            style={{
              margin: "0 0.5rem 4rem 0rem",
              paddingTop: 0,
              width: "16rem",
            }}
          >
            <img className="project_sub_title img" src={bg} />
            <div className="blur-botton"></div>

            <h4 className="order">Contact Us Via Whatsapp</h4>
          </div>{" "}
        </a>
      </div>
    </div>
  );
}
