import { Grid } from "@mui/material";
import Project from "./features/body";
import Nav from "./features/navbar";

export default function Home() {
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <h1
            style={{
              marginTop: "10rem",
              textAlign: "center",
              color: "orange",
            }}
          >
            Welcome to Varlc Services
          </h1>
        </Grid>
        <Grid item xs={12}>
          <Project />
        </Grid>
      </Grid>
    </div>
  );
}
