import bg from "./images/bg.jpg";
import "./nav.css";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
// const { motion } = require("framer-motion");
// import NavLink from "next/NavLink";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { HiMenuAlt4 } from "react-icons/hi";

export default function Nav() {
  const [move, setMove] = useState(false);
  function handleOpen() {
    setMove(true);
  }
  function handleClose() {
    setMove(false);
  }
  // useEffect(() => {
  //   window.onscroll = function () {
  //     scrollFunction();
  //   };
  // }, []);

  // function scrollFunction() {
  //   if (
  //     document.body.scrollTop > 70 ||
  //     document.documentElement.scrollTop > 70
  //   ) {
  //     document.getElementById("header").style.boxShadow = "0px 0px 1px gray";
  //   } else {
  //     document.getElementById("header").style.boxShadow = "0px 0px 0px white";
  //   }
  // }
  return (
    <div style={{ zIndex: 4 }}>
      {/* for desktop */}
      <nav className="nav-container">
        <img src={bg} alt=" " className="nav-img" />
        <div className="blurry-image"></div>

        <NavLink to="/">
          {" "}
          <h3 className="logo">Varlc Services </h3>
        </NavLink>

        <ul>
          <NavLink to="/">
            <a>
              {" "}
              <li>Home</li>
            </a>
          </NavLink>
          <NavLink to="./contact">
            <a>
              <li>Contact</li>
            </a>
          </NavLink>
          {/* <NavLink to="/pricing">
            <a>
              <li>Pricing</li>
            </a>
          </NavLink> */}
        </ul>
        <ul>
          <NavLink to="./track">
            <li className="nav-login nav-button">Track Order</li>
          </NavLink>
          {/* <NavLink to="/sign-up">
            <li className="nav-signup nav-button">Sign up</li>
          </NavLink> */}
        </ul>
      </nav>

      {/* for mobile */}
      <nav className="nav-container-mobile">
        <img src={bg} alt=" " className="nav-img" />
        <div className="blurry-image"></div>

        <ul>
          {move ? (
            <li
              style={{
                marginRight: "1.5rem",
                marginLeft: "0.5rem",
                marginTop: "0.3rem",
                fontSize: "2rem",
                fontWeight: "bold",
              }}
              onClick={handleClose}
            >
              x
            </li>
          ) : (
            <li className="menu-toggle">
              <HiMenuAlt4 className="open" onClick={handleOpen} />
            </li>
          )}
        </ul>

        {/* mobile */}
        <ul className="nav-content-mobile">
          <NavLink to="/">
            {" "}
            <h3 className="logo">Varlc Services </h3>
          </NavLink>

          {move && <div onClick={handleClose} className="mobile-blur"></div>}
          {move && (
            <div
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.1,
                duration: 0.55,
              }}
              className="mobile-nav"
            >
              <li onClick={handleClose}>
                <NavLink to="/">Home</NavLink>
              </li>
              <hr />
              <li onClick={handleClose}>
                <NavLink to="/contact">Contact</NavLink>
              </li>
              <hr />
              {/* <li>
                <NavLink to="/pricing">
                  <a className="pricing-btn">Pricing
                </NavLink>
              </li> */}
              {/* <hr /> */}
              <li onClick={handleClose}>
                <NavLink to="./track">Track Order</NavLink>
              </li>

              {/* <li>
                <NavLink to="/sign-up">
                  <a className="register-btn">Sign up</a>
                </NavLink>
              </li> */}
            </div>
          )}
        </ul>

        {/* <ul>
          <NavLink to="/sign-in">
            <li className="nav-login nav-button">Login</li>
          </NavLink>
        </ul> */}
      </nav>
    </div>
  );
}
