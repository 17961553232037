import { NavLink } from "react-router-dom";
import bg from "../components/features/images/bg.jpg";

export default function Payment() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "7rem",
        textAlign: "center",
      }}
    >
      <h3 style={{ color: "orange" }}>Payment</h3>
      <i>Make payment using the details</i>
      <div>
        <h3>Acct: 1234567890</h3>
        <h3>Bank: Zenith</h3>
        <h3>Description: Email and Transaction Number (1034T)</h3>
      </div>
      <br />
      <br />
      <NavLink to="/track">
        <div
          className="img-botton"
          style={{
            // margin: "0 0.5rem 4rem 0rem",
            paddingTop: 0,
            width: "16rem",
          }}
        >
          <img className="project_sub_title img" src={bg} />
          <div className="blur-botton"></div>

          <h4 className="order">Track Order</h4>
        </div>{" "}
      </NavLink>
      <a href="http://wa.me/2347015776954">
        <div
          className="img-botton"
          style={{
            // margin: "0 0.5rem 4rem 0rem",
            paddingTop: 0,
            width: "16rem",
          }}
        >
          <img className="project_sub_title img" src={bg} />
          <div className="blur-botton"></div>

          <h4 className="order">Contact Us Via Whatsapp</h4>
        </div>{" "}
      </a>
    </div>
  );
}
