import { ThemeProvider } from "@mui/material";
import React from "react";
import theme from "./styles";
import "./body.css";
import CardReport from "./CardReport";
import CardTerm from "./CardTerm";
import CardLog from "./CardLog";
import CardWork from "./CardWork";

export default function Project() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CardWork />
        <CardTerm />
        <CardReport />
        <CardLog />
      </ThemeProvider>
    </div>
  );
}
