import { supabase } from "./features/client";
import bg from "./features/images/bg.jpg";
import { useEffect, useState } from "react";
import Datefield from "./features/datefield";
import Textfield from "./features/textfield";
import "./termpaper.css";
import { NavLink, useNavigate } from "react-router-dom";

export default function Workbook() {
  const [email, setEmail] = useState();
  const [val, setVal] = useState("");
  const [value, setValue] = useState("");
  const [order, setOrder] = useState([]);
  const [name, setName] = useState("");
  const [topic, setTopic] = useState("");
  const [course, setCourse] = useState("");
  const [price, setPrice] = useState("");
  const [doc, setDoc] = useState("");
  const [lecturer, setLecturer] = useState("");
  const [reg, setReg] = useState("");
  const [phone, setPhone] = useState("");
  const [faculty, setFaculty] = useState("");
  const [department, setDepartment] = useState("");
  const [pickup, setPickup] = useState("");
  const [due, setDue] = useState("");
  const [track, setTrack] = useState("");
  const [datum, setDatum] = useState([]);
  const [datu, setDatu] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    total();
    list();
    list2();
  }, []);
  async function total() {
    let { data: orders, error } = await supabase
      .from("ordersWork")
      .select("id");
    setTrack(orders.length + 1000 + "W");
  }

  async function list() {
    let { data: term, error } = await supabase.from("workbook").select("*");
    setDatum(term);
  }
  async function list2() {
    let { data: term, error } = await supabase.from("location").select("*");
    setDatu(term);
  }
  async function send() {
    const { data, error } = await supabase.from("ordersWork").insert([
      {
        name,
        email,
        topic,
        doc,
        lecturer,
        reg,
        phone,
        faculty,
        department,
        due,
        track,
        val,
        value,
      },
    ]);
    if (error) {
      alert("try again");
    } else {
      navigate("/payment");
    }
  }
  console.log(val);
  return (
    <div className="term-container">
      <h3 style={{ color: "orange" }}>Workbook Filling Details</h3>
      <div className="term-sub-container">
        <Textfield
          type="name"
          placeholder="Full Name"
          id="name"
          label="Name"
          setState={setName}
          value={name}
        />

        <Textfield
          type="email"
          placeholder="Email"
          id="email"
          label="Email"
          setState={setEmail}
          value={email}
        />
      </div>
      <div className="term-sub-container">
        {/* <Textfield
          type="text"
          placeholder="Report Topic--optional"
          id="topic"
          label="Report Topic --optional"
          setState={setTopic}
          value={topic}
        /> */}
        <Textfield
          type="text"
          placeholder="Phone Number"
          id="phone"
          label="Phone Number"
          setState={setPhone}
          value={phone}
        />
        <div style={{ display: "flex" }}>
          <select
            className="form-input select"
            value={val}
            onChange={(e) => setVal(e.target.value)}
          >
            <option>Select Workbook Course</option>

            {datum.map((opt) => (
              <option key={opt.id}>
                {opt.termpaper} Workbook: N{opt.price}
              </option>
            ))}
          </select>
          {/* <h3 className="price-tag">
            PRICE: <i style={{ color: "gray" }}>N2500</i>
          </h3> */}
          <h1 style={{ display: "none" }}>{val}</h1>
        </div>
      </div>

      <div className="term-sub-container">
        {/* <Textfield
          type="text"
          placeholder="Reg. No"
          id="topic"
          label="Reg. No"
          setState={setReg}
          value={reg}
        /> */}
      </div>
      {/* <div className="term-sub-container">
        <Textfield
          type="text"
          placeholder="Faculty"
          id="faculty"
          label="Faculty"
          setState={setFaculty}
          value={faculty}
        />

        <Textfield
          type="email"
          placeholder="Department"
          id="department"
          label="Department"
          setState={setDepartment}
          value={department}
        />
      </div> */}
      <div className="term-sub-container">
        <div style={{ display: "flex" }}>
          <select
            className="form-input selects"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <option>Select Pickup Location</option>

            {datu.map((opt) => (
              <option key={opt.id}>{opt.location}</option>
            ))}
          </select>
          <h1 style={{ display: "none" }}>{val}</h1>
          <Datefield
            type="date"
            placeholder="Due Date"
            id="from1"
            label="Due Date"
            setState={setDue}
            value={due}
          />
        </div>
      </div>
      <div>
        <h3 style={{ width: "80vw", textAlign: "center" }}>
          {/* should be able to copy */}
          Order Transaction Number: {track} <br />
          <i
            style={{
              width: "80vw",
              textAlign: "center",
              fontSize: "0.8rem",
              marginBottom: ".5rem",
              fontWeight: "lighter",
            }}
          >
            keep safe for payment and tracking order
          </i>
        </h3>
      </div>
      <div className="term-sub-container">
        {/* <NavLink to="./payment"> */}
        <div className="botton" onClick={send}>
          <img className="project_sub_title img" src={bg} />
          <div className="blur-botton"></div>

          <h4 className="order">Save Details and Pay</h4>
        </div>
        {/* </NavLink> */}
      </div>
    </div>
  );
}
