import { Button, Input, TextField } from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";

const colors = {
  primary: "#ffffff",
  secondary: "#ffffff",
  success: "#ffffff",
  info: "#ffffff",
  danger: "#ffffff",
  warning: "#ffffff",
  plane: "#ffffff",
  back: "#ffffff",
  black: "#ffffff",
};

export const MyText = styled(TextField)(() => ({
  borderRadius: "0.3rem 0.3rem 0 0",
  backgroundColor: "#ffffff",
  color: "#ffffff",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

export default theme;
