// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Track from "./components/track";
import About from "./components/about";
import Home from "./components/home";
import Scholarship from "./components/scholarships";
import Services from "./components/services";
import Contact from "./components/contact";
import Logbook from "./components/logbook";
import Report from "./components/reportwriting";
import Term from "./components/termpaper";
import Workbook from "./components/workbookfilling";
import Nav from "./components/features/navbar";
import NotFound from "./NotFound";
import Log from "./folder/Log";
import TermPaper from "./folder/TermPaper";
import Work from "./folder/Work";
import ReportWriting from "./folder/ReportWriting";
import Footer from "./components/features/footer";
import Payment from "./folder/Payment";
import Admin from "./admin/admin";
import AddTerm from "./admin/addTerm";
import Pickup from "./admin/pickup";
import List from "./admin/orderList";

function App() {
  return (
    <>
      <BrowserRouter>
        <Nav />
        <main>
          <Routes>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="services" element={<Services />} />
            <Route path="scholarship" element={<Scholarship />} />
            <Route path="track" element={<Track />} />
            <Route path="contact" element={<Contact />} />
            <Route path="logbook" element={<Log />} />
            <Route path="report" element={<ReportWriting />} />
            <Route path="term-paper" element={<TermPaper />} />
            <Route path="workbook" element={<Work />} />
            <Route path="payment" element={<Payment />} />

            <Route path="logbook/detail" element={<Logbook />} />
            <Route path="report/detail" element={<Report />} />
            <Route path="term-paper/detail" element={<Term />} />
            <Route path="workbook/detail" element={<Workbook />} />
            {/* 
            <Route path="varlc/admin/addterm" element={<AddTerm />} />
            <Route path="varlc/admin/pickup" element={<Pickup />} />
            <Route path="varlc/admin/list" element={<List />} />
            <Route path="varlc/admin" element={<Admin />} /> */}

            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
