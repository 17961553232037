export default function Footer() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        fontSize: "0.8rem",
        position: "fixed",
        bottom: "0",
        color: "gray",
        zIndex: -1,
      }}
    >
      <hr />
      Copyright © 2023 Varlc Services, All rights reserved.
    </div>
  );
}
