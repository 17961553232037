
import ProjectCard from "./ProjectCardReport";
import { Paper, Grid, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { supabase } from "./client";
import { NavLink } from "react-router-dom";

export default function CardReport() {
  
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postPerPage, setPostPerPage] = React.useState(6);
  const [filter, setFilter] = React.useState("");
  const [term, setTerm] = React.useState([]);

  useEffect(() => {
    datum();
  }, []);
  async function datum() {
    let { data: report, error } = await supabase.from("report").select("*");
    setTerm(report);
  }

  const searchText = (event) => {
    setFilter(event.target.value);
  };

  let dataSearch = term.filter((element) => {
    return Object.keys(element).some((key) =>
      element[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPost = dataSearch.slice(indexOfFirstPost, indexOfLastPost);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const display = currentPost.map((element, index) => {
    return (
      <Grid item xs={6} sm={6} md={6} lg={4} xl={3}>
        <Paper
          style={{
            height: "fit-content",
            marginBottom: "1rem",
            padding: "0 1rem 2rem 1rem",
          }}
        >
          <ProjectCard
            key={element.id}
            project={element.report}
            projectDetail={element.price}
          />
        </Paper>
      </Grid>
    );
  });
  return (
    <div
      className="con"
      component="form"
      // sx={{ width: "60%" }}
      noValidate
      autoComplete="off"
    >
      {/* term paper*/}
      <h3 style={{ marginTop: "2rem", marginRight: "auto", color: "orange" }}>
        Report Writing{" "}
        <NavLink to="/report">
          <i style={{ color: "gray", fontSize: "0.8rem" }}> Show all</i>
        </NavLink>
      </h3>

      <Grid
        container
        rowSpacing={{ xs: 0.5, sm: 0.5, md: 0.5 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ marginTop: "0rem" }}
      >
        {display}
      </Grid>
    </div>
  );
}
