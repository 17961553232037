import ProjectCard from "../components/features/ProjectCard";
import { TextField, Box, Paper, Grid, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { MyText } from "../components/features/styles";
import Page from "../components/features/Page";
import Textfield from "../components/features/textfield";
import Searchfield from "../components/features/searchfield";
import { supabase } from "../components/features/client";

const projects = [
  {
    value: "",
    label: "Select",
  },
  {
    value: "Calculator",
    label: "Calculator",
  },
  {
    value: "Education",
    label: "Education",
  },
  {
    value: "Generator",
    label: "Generator",
  },
  {
    value: "Fullstack",
    label: "Fullstack",
  },
  {
    value: "Front",
    label: "FrontEnd UIs",
  },
  {
    value: "Game",
    label: "Game",
  },
  {
    value: "RandomAPI",
    label: "RandomAPI",
  },
];

export default function TermPaper(props) {
  // const [project, setProjects] = React.useState("all");

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postPerPage, setPostPerPage] = React.useState(6);
  const [filter, setFilter] = React.useState("");
  const [term, setTerm] = React.useState([]);

  useEffect(() => {
    datum();
  }, []);
  async function datum() {
    let { data: term, error } = await supabase.from("term").select("*");
    setTerm(term);
  }
  const searchText = (event) => {
    setFilter(event.target.value);
  };

  let dataSearch = term.filter((element) => {
    return Object.keys(element).some((key) =>
      element[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPost = dataSearch.slice(indexOfFirstPost, indexOfLastPost);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const display = currentPost.map((element, index) => {
    return (
      <Grid item xs={6} sm={6} md={6} lg={4} xl={3}>
        <Paper
          style={{
            height: "fit-content",
            marginBottom: "1rem",
            padding: "0 1rem 2rem 1rem",
          }}
        >
          <ProjectCard
            key={element.id}
            project={element.termpaper}
            img={element.img}
            projectDetail={element.price}
            date={element.date}
            themeToggle={props.themeToggle}
            url={element.url}
          />
        </Paper>
      </Grid>
    );
  });
  return (
    <div
      className="con"
      component="form"
      // sx={{ width: "60%" }}
      noValidate
      autoComplete="off"
      style={{ paddingTop: "7rem" }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={6} sm={7.5} md={8} lg={8} xl={8} sx={{ color: "white" }}>
          <Searchfield
            type="name"
            placeholder="Name"
            id="outlined-basic"
            label="Search Bar"
            setState={searchText.bind(this)}
            value={filter}
          />
        </Grid>
      </Grid>

      {/* term paper*/}
      <h3 style={{ marginTop: "2rem", marginRight: "auto", color: "orange" }}>
        Term Paper{" "}
      </h3>
      <Grid
        container
        rowSpacing={{ xs: 0.5, sm: 0.5, md: 0.5 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ marginTop: "0rem" }}
      >
        {display}
        <Grid item xs={12}>
          <Page
            style={{ height: "2rem" }}
            postPerPage={postPerPage}
            totalPosts={dataSearch.length}
            paginate={paginate}
            themeToggle={props.themeToggle}
            currentPage={currentPage}
          />
        </Grid>
      </Grid>
    </div>
  );
}
