import { BsFillEyeFill } from "react-icons/bs";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import bg from "./images/bg.jpg";
import { NavLink } from "react-router-dom";

export default function ProjectCard(props) {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        className="co"
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <h2 style={{ textAlign: "center" }}>{props.project} Logbook</h2>
        </Grid>
        <Grid item>
          <div>
            PRICE: <i style={{ color: "gray" }}>N{props.projectDetail}</i>
          </div>
        </Grid>
        {/* <Grid item>
          <div>{props.date}</div>
        </Grid> */}
        <Grid item>
          <NavLink to="/logbook/detail">
            {" "}
            <div className="img-botton">
              <img className="project_sub_title img" src={bg} />
              <div className="blur-botton"></div>

              <h4 className="order">Order Service</h4>
            </div>{" "}
          </NavLink>
        </Grid>
      </Grid>
    </Box>
  );
}
